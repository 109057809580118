import React, { useContext } from "react"
import Helmet from "react-helmet"
import { withPrefix } from "gatsby"
import { store } from "src/store"

const Layout = ({ title, children }) => {
  const sessionMachine = useContext(store)
  return (
    <main>
      <Helmet>
        <script
          src={withPrefix("bootstrap.bundle.js")}
          type="text/javascript"
        />
      </Helmet>
      <title>{title}</title>
      <div className="container">{children}</div>
      {process.env.NODE_ENV === "development" ? (
        <>
          <hr />
          <div className="container">
            <pre>{JSON.stringify(sessionMachine.state.context, null, 2)}</pre>
          </div>
        </>
      ) : null}
    </main>
  )
}
export default Layout
